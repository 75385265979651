import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { getCatalogFilters } from "../../assets/api/admin/admin_api";
// import { useState } from "react";
import debounce from "lodash.debounce";

const Filters = (props) => {
  const { setDataFilterTo } = props;
  const [family, setFamily] = useState([]);
  const [brand, setBrand] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [names, setNames] = useState([]);
  const [manufacterids, setManufacterIds] = useState([]);
  const [barcodes, setBarcodes] = useState([]);

  const [filteredNames, setFilteredNames] = useState([]);
  const [filteredManufacterIds, setFilteredManufacterIds] = useState([]);
  const [filteredBarcodes, setFilteredBarcodes] = useState([]);
  const [dynamicFilters, setDynamicFilters] = useState([]);

  const [tempDataFilter, setTempDataFilter] = useState({
    name: "",
    brand: "",
    manufacturer: "",
    manufacturer_id: "",
    barcode: "",
    family: "",
    is_suggested: "",
    is_cemex: "",
  });

  console.log(names);
  console.log(manufacterids);
  console.log(barcodes);

  const fetchSuggestions = async (filters) => {
    try {
      const result = await getCatalogFilters(
        sessionData?.access_token,
        filters
      );
      if (result?.code === 200) {
        if (filters.name) setFilteredNames(result?.names || []);
        if (filters.manufacturer_id)
          setFilteredManufacterIds(result?.manufacturer_ids || []);
        if (filters.barcode) setFilteredBarcodes(result?.barcodes || []);
      }
    } catch (e) {
      console.error("Error fetching suggestions:", e);
    }
  };

  const debounceFetchSuggestions = useCallback(
    debounce((filters) => fetchSuggestions(filters), 300),
    []
  );

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    const newValues = {
      ...tempDataFilter,
      [name]: value,
    };
    setTempDataFilter(newValues);

    if (value.trim() === "") {
      if (name === "name") setFilteredNames([]);
      if (name === "manufacturer_id") setFilteredManufacterIds([]);
      if (name === "barcode") setFilteredBarcodes([]);

      setDynamicFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.field !== name)
      );

      return;
    }

    if (value.length >= 3) {
      debounceFetchSuggestions({ [name]: value });
    }

    setDynamicFilters((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) => filter.field !== name
      );
      updatedFilters.push({ field: name, value });
      return updatedFilters;
    });
  };

  const handleSelectSuggestion = (field, value) => {
    const newValues = {
      ...tempDataFilter,
      [field]: value,
    };
    setTempDataFilter(newValues);

    if (field === "name") setFilteredNames([]);
    if (field === "manufacturer_id") setFilteredManufacterIds([]);
    if (field === "barcode") setFilteredBarcodes([]);

    setDynamicFilters((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) => filter.field !== field
      );
      updatedFilters.push({ field, value });
      return updatedFilters;
    });
  };

  function handleChangeSelect(evt) {
    const { name, value } = evt;
    const newValues = {
      ...tempDataFilter,
      [name]: value,
    };

    setTempDataFilter(newValues);
  }

  const handleIsSuggested = (e) => {
    const suggested = e.target.checked ? "si" : "";
    const newValues = { ...tempDataFilter, is_suggested: suggested };
    setTempDataFilter(newValues);
  };

  const handleIsCEMEX = (e) => {
    const cemex = e.target.checked ? "si" : "";
    const newValues = { ...tempDataFilter, is_cemex: cemex };
    setTempDataFilter(newValues);
  };

  const sendFilters = () => {
    const payload = {
      filters: dynamicFilters,
      otherFilters: tempDataFilter,
    };
    console.log("Payload enviado:", payload);
    setDataFilterTo(payload);
  };

  const handleResetFilters = () => {
    const newValues = {
      name: "",
      brand: "",
      manufacturer: "",
      manufacturer_id: "",
      barcode: "",
      family: "",
      is_suggested: "",
      is_cemex: "",
    };

    setTempDataFilter(newValues);
    setDataFilterTo(newValues);
    setDynamicFilters([]);
  };

  const sessionData = JSON.parse(
    localStorage.getItem("tokenSession") || "null"
  );

  const getFilter = async () => {
    try {
      const result = await getCatalogFilters(sessionData?.access_token);
      console.log(result);
      if (result?.code === 200) {
        setFamily(result?.family);
        setManufacturer(result?.manufacturer);
        setBrand(result?.brand);
        setNames(result?.names);
        setManufacterIds(result?.manufacturer_ids);
        setBarcodes(result?.barcodes);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    getFilter();
  }, []);

  const optionsFamily = family?.map((item) => ({
    value: item.family,
    label: item.family,
    name: "family",
  }));

  const optionsBrand = brand?.map((item) => ({
    value: item.brand,
    label: item.brand,
    name: "brand",
  }));

  const optionsManufacturer = manufacturer?.map((item) => ({
    value: item.manufacturer,
    label: item.manufacturer,
    name: "manufacturer",
  }));

  const renderSuggestions = (suggestions, field) =>
    suggestions.length > 0 && (
      <ul className="suggestion-list absolute bg-white border rounded-[10px] mt-[75px] z-50 min-h-auto max-h-[200px] overflow-y-scroll w-[250px]">
        {suggestions.map((item, index) => (
          <li
            key={index}
            onClick={() => handleSelectSuggestion(field, item[field])}
            className="suggestion-item cursor-pointer  p-[5px] hover:bg-slate-100"
          >
            {item[field]}
          </li>
        ))}
      </ul>
    );

  return (
    <>
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2 h-[250px] z-50">
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Marca </label>
          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300 z-50"
            name="brand"
            placeholder="Selecciona una marca"
            options={optionsBrand}
            value={{
              value: tempDataFilter?.brand || "default",
              label: tempDataFilter?.brand || "Selecciona una marca",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="brand"
            value={tempDataFilter.brand}
            onChange={handleChange}
            placeholder="Filtra por la marca"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1 relative ">
          <label>Nombre</label>
          <input
            type="text"
            name="name"
            value={tempDataFilter.name}
            onChange={handleChange}
            placeholder="Filtra por el nombre"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
          {renderSuggestions(filteredNames, "name")}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Familia/Categoría</label>

          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300  z-50"
            name="family"
            placeholder="Selecciona una familia/categoríalia"
            options={optionsFamily}
            value={{
              value: tempDataFilter?.family || "default",
              label:
                tempDataFilter?.family || "Selecciona una Familia/Categoría",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="family"
            value={tempDataFilter.family}
            onChange={handleChange}
            placeholder="Filtra por la familia"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Fabricante</label>
          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300  z-50"
            name="manufacturer"
            placeholder="Selecciona un fabricante"
            options={optionsManufacturer}
            value={{
              value: tempDataFilter?.manufacturer || "default",
              label: tempDataFilter?.manufacturer || "Selecciona un fabricante",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="manufacturer"
            value={tempDataFilter.manufacturer}
            onChange={handleChange}
            placeholder="Filtra por fabricante"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        {/* <article className="input-container col-span-1 flex flex-col gap-1">
          <label>No. de fabricante</label>
          <input
            type="text"
            name="manufacturer_id"
            value={tempDataFilter.manufacturer_id}
            onChange={handleChange}
            placeholder="Filtra por fabricante"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          />
        </article> */}
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>No. de fabricante</label>
          <input
            type="text"
            name="manufacturer_id"
            value={tempDataFilter.manufacturer_id}
            onChange={handleChange}
            placeholder="Filtra por fabricante"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
          {renderSuggestions(filteredManufacterIds, "manufacturer_id")}
        </article>
        {/* <article className="input-container col-span-1 flex flex-col gap-1 ">
          <label>Codigo de barras</label>
          <input
            type="text"
            name="barcode"
            value={tempDataFilter.barcode}
            onChange={handleChange}
            placeholder="Filtra por codigo de barras"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          />
        </article> */}

        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Código de barras</label>
          <input
            type="text"
            name="barcode"
            value={tempDataFilter.barcode}
            onChange={handleChange}
            placeholder="Filtra por código de barras"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
          {renderSuggestions(filteredBarcodes, "barcode")}
        </article>
        <ul className="ks-cboxtags flex gap-2 items-center col-span-2  pb-3 w-auto">
          <li>
            <input
              id="is_suggested"
              type="checkbox"
              name="is_suggested"
              checked={tempDataFilter?.is_suggested !== ""}
              onChange={handleIsSuggested}
            />
            <label htmlFor="is_suggested">Producto sugerido</label>
          </li>
          <li>
            <input
              id="is_cemex"
              type="checkbox"
              name="is_cemex"
              checked={tempDataFilter?.is_cemex !== ""}
              onChange={handleIsCEMEX}
            />
            <label htmlFor="is_cemex">Producto CEMEX</label>
          </li>
          <button
            onClick={() => sendFilters()}
            type="button"
            className="btn btn-primary  w-[200px]"
          >
            Buscar
          </button>
          <button
            onClick={() => handleResetFilters()}
            type="button"
            className="btn btn-primary w-[300px] px-0 2xl:w-[200px]"
          >
            Borrar filtros
          </button>
        </ul>
      </div>
    </>
  );
};

Filters.propTypes = {
  dataToFilter: PropTypes.any,
  dataFilterTo: PropTypes.any,
  setDataFilterTo: PropTypes.any,
};

export default Filters;
