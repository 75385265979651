import { Empty, Table } from "antd";
import { useEffect, useState } from "react";
import Filters from "../general/filters";
import Loader from "../general/loader";
import {
  getInfoConstrurama,
  getItemsConstruramasCompare,
  removeItemConstruramaList,
  setItemConstrurama,
} from "../../assets/api/construrama/api_construrama";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCatalogPim, getConfigs } from "../../assets/api/admin/admin_api";

const TablePim = () => {
  // let page = 1;
  const [page, setPage] = useState(1);
  const [dataPim, setData] = useState([]);
  const { from, to, total, current_page, last_page } = dataPim;
  const [dataConstruramaList, setDataConstruramaList] = useState([]);
  const [loder, setLoader] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [auxData, setAuxData] = useState(false);
  const [urlConfigForm, SeturlConfigForm] = useState();
  // this is only forn construramas users
  const [isFinished, setIsFinished] = useState(false);
  // const [errorCode, setErrorCode] = useState(false);
  const [errorCodes, setErrorCodes] = useState({});

  const [codeValues, setCodeValues] = useState({});

  const handleCodeChange = (index, value) => {
    setCodeValues((prev) => ({
      ...prev,
      [index]: value,
    }));

    setErrorCodes({});
  };

  // const maxLength = 60;
  const [dataFilterTo, setDataFilterTo] = useState({
    name: "",
    brand: "",
    manufacturer: "",
    manufacturer_id: "",
    barcode: "",
    family: "",
    is_suggested: "",
    is_cemex: "",
  });

  const sessionData = JSON.parse(
    localStorage.getItem("tokenSession") || "null"
  );

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const totalPages = last_page;

    if (totalPages <= maxVisiblePages + 1) {
      // Si hay 6 o menos páginas, mostrarlas todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (page <= 3) {
        // Si la página actual es 1, 2 o 3, muestra las primeras cinco y la última página con puntos
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (page >= totalPages - 2) {
        // Si la página actual está entre las tres últimas, muestra la primera página, puntos y las últimas cinco
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Si está en alguna otra página, muestra la primera, puntos, las páginas alrededor de la actual, más puntos y la última
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = page - 2; i <= page + 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
    }
  };

  const columns = [
    {
      title: "Producto",
      dataIndex: "img_url",
      fixed: "left",
      width: 100,
      render: (text) => (
        <div className="w-[50px] ">
          <img
            className="w-[100%] max-h-[72px]"
            src={text}
            alt="img-product"
          ></img>
        </div>
      ),
    },

    {
      title: "Nombre",
      dataIndex: "name",
      width: 520,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (name) => (
        <div
          style={{
            width: "500px",
            fontSize: "18px",
            whiteSpace: "normal", // Asegura que el texto haga saltos de línea
            wordWrap: "break-word",
          }}
        >
          {name}
          {/* {name.length > maxLength
            ? name.substring(0, maxLength) + "..."
            : name} */}
        </div>
      ),
    },
    // {
    //   title: "Familias",
    //   width: 500,
    //   render: (element) => (
    //     <p>
    //       {element?.category_1} / {element?.category_2} / {element?.category_3}{" "}
    //     </p>
    //   ),
    // },
    {
      title: "Unidad de Venta",
      dataIndex: "units",
      sorter: (a, b) => a.units.localeCompare(b.name),
      width: 200,
      render: (units) => <p className="text-[18px]">{units}</p>,
    },
    {
      title: "Marca",
      dataIndex: "brand",
      width: 200,
      sorter: (a, b) => a.brand.localeCompare(b.brand),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (brand) => <p className="text-[18px]">{brand}</p>,
    },
    {
      title: "Fabricante",
      dataIndex: "manufacturer",
      width: 200,
      sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (manufacturer) => <p className="text-[18px]">{manufacturer}</p>,
    },
    {
      title: "Numero de Fabricante",
      dataIndex: "manufacturer_id",
      width: 200,
      sorter: (a, b) => a.manufacturer_id.localeCompare(b.manufacturer_id),
      render: (manufacturer_id) => (
        <p className="text-[18px]">{manufacturer_id}</p>
      ),
    },
    {
      title: " Codigo de barras",
      dataIndex: "barcode",
      width: 200,
      render: (barcode) => <p className="text-[18px]">{barcode}</p>,
    },
  ];

  if (sessionData?.user?.type === "admin") {
    const newColumn = {
      title: "PIM",
      dataIndex: "pim_code",
      width: 200,
      render: (pim_code) => <p className="text-[18px]">{pim_code}</p>,
    };
    columns.splice(1, 0, newColumn);
  }

  if (sessionData?.user?.type === "construrama") {
    columns.splice(1, 0, {
      title: "Código actual",
      dataIndex: "code",
      width: 150,
      render: (_, record) => {
        const currentValue = codeValues[record.pim_code] || "";

        const isInConstruramaList =
          Array.isArray(dataConstruramaList) &&
          dataConstruramaList.some((item) => item.pim_code === record.pim_code);

        return (
          <div className="w-[130px] flex items-center justify-between gap-1">
            {isInConstruramaList && currentValue ? (
              <span>{currentValue}</span>
            ) : (
              <input
                type="text"
                placeholder="Ingresa el código"
                className={`border p-2 rounded-[5px] w-[100%] ${
                  errorCodes[record.pim_code]
                    ? "!border-red-500"
                    : "border-gray-300"
                }`}
                minLength={1}
                maxLength={20}
                value={currentValue}
                onChange={(e) =>
                  handleCodeChange(record.pim_code, e.target.value)
                }
              />
            )}
          </div>
        );
      },
    });
  }

  if (isFinished !== 1 && sessionData?.user?.type === "construrama") {
    columns.push({
      title: "Accion",
      dataIndex: "pim_code",
      fixed: "right",
      width: 100,
      render: (index) => (
        <div className="w-[100px] flex items-center justify-between gap-1">
          {Array.isArray(dataConstruramaList) &&
          dataConstruramaList.some((item) => item.pim_code === index) ? (
            <button
              onClick={() => deleteItem(index)}
              className="bg-[#ff3500]  text-white p-2.5 w-[200px] rounded-md ml-2 "
            >
              Eliminar
            </button>
          ) : (
            <button
              onClick={() => saveItem(index)}
              className="bg-[#1C3661]  text-white p-2.5 w-[200px] rounded-md ml-2 "
            >
              Agregar
            </button>
          )}
        </div>
      ),
    });
  }

  const notify = () =>
    toast.success("Producto agregado", {
      autoClose: 1200,
      hideProgressBar: true,
    });

  const notifyDelete = () =>
    toast.success("Producto eliminado", {
      autoClose: 1200,
      hideProgressBar: true,
    });
  // const prevPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };
  // const nextPage = () => {
  //   const aux = page + 1;
  //   if (aux <= dataPim?.last_page) {
  //     setPage(aux);
  //   }
  // };

  const getAllItems = async () => {
    setLoader(true);
    try {
      const response = await getCatalogPim(
        sessionData?.access_token,
        page,
        dataFilterTo
      );
      setLoader(false);
      setData(response);
    } catch (e) {
      setLoader(false);
    }
  };

  // this will work only for construrama users, its the function to insert in the databae
  // the data selected

  // const saveItem = async (index) => {
  //   const data = dataPim?.data;
  //   const findData = data.find((object) => object.pim_code == index);
  //   setAuxData(true);
  //   try {
  //     setOpenLoader(true);
  //     const response = await setItemConstrurama(sessionData, findData);
  //     if (response?.status === 200) {
  //       setOpenLoader(false);
  //       notify();
  //     } else {
  //       toast.warning("Este producto ya fue agregado anteriormente", {
  //         autoClose: 1200,
  //         hideProgressBar: true,
  //       });
  //       setOpenLoader(false);
  //     }
  //   } catch (e) {
  //     setOpenLoader(false);
  //     toast.error("Error al agregar", {
  //       autoClose: 1200,
  //       hideProgressBar: true,
  //     });
  //   }
  //   setAuxData(false);
  // };

  const saveItem = async (index) => {
    const code = codeValues[index];

    if (!code) {
      setErrorCodes((prev) => ({
        ...prev,
        [index]: true,
      }));

      toast.warning(
        "Por favor, ingresa un código antes de agregar el producto.",
        {
          autoClose: 1200,
          hideProgressBar: true,
        }
      );
      return;
    }

    const data = dataPim?.data;
    const findData = data.find((object) => object.pim_code === index);
    const findDataWithCode = {
      ...findData,
      code,
    };
    setAuxData(true);
    try {
      setOpenLoader(true);
      const response = await setItemConstrurama(sessionData, findDataWithCode);
      if (response?.status === 200) {
        setOpenLoader(false);
        notify();
        setErrorCodes((prev) => ({
          ...prev,
          [index]: false,
        }));
      } else {
        const errorMessages = response.errors || {}; // Obtener errores si existen
        const errorList = Object.values(errorMessages).flat();
        errorList.forEach((message) => {
          toast.warning(message, {
            autoClose: 1200,
            hideProgressBar: true,
          });
        });
        setOpenLoader(false);
      }
    } catch (e) {
      setOpenLoader(false);
      toast.error("Error al agregar", {
        autoClose: 1200,
        hideProgressBar: true,
      });
    }
    setAuxData(false);
  };

  const deleteItem = async (index) => {
    setAuxData(true);
    // we need to create the unq_index that will remove in the items list
    const id_construrama = index + "-" + sessionData?.user?.email;
    try {
      setOpenLoader(true);
      const response = await removeItemConstruramaList(
        sessionData,
        id_construrama
      );
      if (response?.status === 200) {
        setOpenLoader(false);
        notifyDelete();
      } else {
        toast.warning("Este producto ya no existe en tu lista", {
          autoClose: 1200,
          hideProgressBar: true,
        });
        setOpenLoader(false);
      }
    } catch (e) {
      setOpenLoader(false);
      toast.error("Error al eliminar", {
        autoClose: 1200,
        hideProgressBar: true,
      });
    }
    setAuxData(false);
  };

  useEffect(() => {
    getAllItems();
  }, [dataFilterTo, page]);

  //this use effect is for compare the list of pims with the
  // list of user construrama pims

  const getItemsForCompare = async () => {
    try {
      const response = await getItemsConstruramasCompare(sessionData);

      if (response) {
        setDataConstruramaList(response);
      }
    } catch (e) {
      console.log(e, " error ");
    }
  };

  const getConstrurama = async () => {
    try {
      const result = await getInfoConstrurama(
        sessionData?.user?.email,
        sessionData?.access_token
      );

      setIsFinished(result?.data?.status);
    } catch (e) {
      console.log("error ", e);
    }
  };

  useEffect(() => {
    if (sessionData?.user?.type === "construrama") {
      getItemsForCompare();
      getConstrurama();
    }
  }, [auxData]);

  useEffect(() => {
    getConfigs(sessionData?.access_token)
      .then((config) => {
        SeturlConfigForm(config?.url_pim_form);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
      });
  }, [sessionData?.access_token]);

  useEffect(() => {
    if (Array.isArray(dataConstruramaList)) {
      const initialValues = dataConstruramaList.reduce((acc, item) => {
        acc[item.pim_code] = item.code || "";
        return acc;
      }, {});
      setCodeValues(initialValues);
    }
  }, [dataConstruramaList]);

  return (
    <>
      <Loader show={loder} />
      <div className=" h-auto flex flex-col gap-[20px] max-w-full p-[20px]">
        <section className="filters-table w-full h-auto ">
          <Filters
            dataFilterTo={dataFilterTo}
            setDataFilterTo={setDataFilterTo}
          />
          <ToastContainer />
        </section>
        <section className="table-pim max-w-full  overflow-x-auto pb-[20px]">
          <Table
            className="border border-slate-100 rounded-lg"
            columns={columns}
            dataSource={dataPim?.data}
            pagination={false}
            key={dataPim}
            scroll={{
              x: "calc(100% + 50%)",
              // y: 800,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <a
                      href={urlConfigForm}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-lg underline"
                    >
                      Si no encuentras productos en el catálogo PIM haz click
                      aqui.
                    </a>
                  }
                />
              ),
            }}
          />
          <div className="flex items-center justify-center pt-4">
            <a
              href={urlConfigForm}
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg underline"
            >
              Si no encuentras productos en el catálogo PIM haz click aqui.
            </a>
          </div>
          <section className="w-full h-[100px] flex items-center justify-between px-5">
            <p className="text-[14px] text-gray-600">
              Mostrando del {from} al {to} de {total} resultados
            </p>
            <article className="flex items-center gap-3">
              <button
                onClick={() => handlePageChange(1)}
                className={`text-[#1C3661] p-2.5 w-[100px] rounded-md ${
                  page === 1
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-orange-500"
                }`}
                disabled={page === 1}
              >
                Primero
              </button>
              <button
                onClick={() => handlePageChange(current_page - 1)}
                className={`bg-[#1C3661] text-white p-2.5 w-[100px] rounded-md ${
                  page === 1 ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={page === 1}
              >
                Anterior
              </button>
              {generatePageNumbers().map((pageNumber, index) => (
                <button
                  key={
                    typeof pageNumber === "number"
                      ? `page-${pageNumber}`
                      : `ellipsis-${index}`
                  }
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-[10px] py-2 rounded-md ${
                    page === pageNumber
                      ? "bg-[#1C3661] text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  disabled={typeof pageNumber !== "number"}
                >
                  {pageNumber}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(current_page + 1)}
                className={`bg-[#1C3661] text-white p-2.5 w-[100px] rounded-md ${
                  page === last_page ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={page === last_page}
              >
                Siguiente
              </button>
              <button
                onClick={() => handlePageChange(last_page)}
                className={`text-[#1C3661] p-2.5 w-[100px] rounded-md ${
                  page === last_page
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-orange-500"
                }`}
                disabled={page === last_page}
              >
                Última
              </button>
            </article>
          </section>
        </section>
      </div>
      <Loader show={openLoader}></Loader>
    </>
  );
};

export default TablePim;
